img {
  border-radius: 5px;
}

.main-content_frame {
  width: 100%;
  height: 20vh;
  display: flex;
  /* flex-wrap: nowrap; */
  /* flex-direction: row; */
  overflow-x: scroll;
  /* font-size: 1rem; */
  font-size: 1rem;
  line-height: 1rem;
}

.main-content_project {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 20vh;
  width: 20vh;
  background-color: rgba(255, 255, 255, 0.9);
  padding: 2px;
  padding-bottom: 8px;
  border-radius: 5px;
  margin: 0 10px;
}

.main-content-project_image {
  border-radius: 5px;
  overflow: hidden;
}

.main-content_top {
  color: #5e9ff2;
  text-align: center;
  font-weight: bold;
}

.main-content_bottom {
  color: #5e9ff2;
  text-align: center;
}
